.HomeSubscribeContainer {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.HomeSubscribeContainer input {
    flex: 0 1 200px;
}

.HomeSubscribeContainer button {
    flex: 0 1 100px;
    margin-left: 20px;
}

.HomeIntroContainer {
    display: flex;
    flex-direction: row;
}

.HomeIntroLogo {
    width: 100px;
    height: 118px;
    background-image: url('../../logo.png');
    background-size: contain;
    flex-shrink: 0;
    align-self: center;
    margin: 10px;
}

@media (max-width:600px) {
    .HomeIntroContainer {
        flex-direction: column;
    }
}