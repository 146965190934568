.TabsContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.TabsHeader {
    background-color: var(--secondary-background-color);
    flex: 0 0 35px;
    padding: 10px;
}

.TabsHeaderTopText {
    text-align: center;
    font-size: 30px;
    color: var(--secondary-font-color);
}

.TabsHeaderTopTextEmphasis {
    /*font-variant: small-caps;*/
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0px;
    font-size: 26px;
}

.TabsBannerTab {
    font-size: 20px;
    color: var(--primary-font-color);
    margin: 5px 15px;
    transition: color 300ms, border-bottom 300ms;
    border-bottom: 2px solid #00000000;
}

.TabsBannerActiveTab {
    color: var(--primary-accent-color);
    border-bottom: 2px solid var(--primary-accent-color);
}

.TabsBannerInactiveTab:hover {
    color: var(--primary-hover-color);
    cursor: pointer;
}

.TabsBannerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.TabsBody {
    flex: 1 1 auto;
    min-height: 0px;
    padding: 10px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--primary-background-color);
}

.TabsBodyContent {
    flex: 0 1 1000px;
    overflow-y: auto;
}

@media (max-width:600px) {
    .TabsBody {
        padding: 10px 10px;
    }
}