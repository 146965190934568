/*
.App {
  --primary-background-color: #18191b;
  --secondary-background-color: #282c34;
  --primary-font-color: #e3e3e3;
  --secondary-font-color: #ffffff;
  --primary-accent-color: #e55846;
  --primary-hover-color: #e6b2ab;
}
*/

.App {
  --primary-background-color: #ffffff;
  --secondary-background-color: #e3e3e3;
  --primary-font-color: #282c34;
  --secondary-font-color: #18191b;
  --primary-accent-color: #e55846;
  --secondary-accent-color: #e6897d;
  --primary-hover-color: #cc3521;
}

h2 {
  font-weight: 500;
  letter-spacing: 0px;
  font-size: 26px;
  color: var(--primary-font-color);
  margin: 15px 0px;
  border-bottom: 2px solid var(--primary-accent-color);
}

h3 {
  font-weight: 450;
  letter-spacing: 0px;
  font-size: 22px;
  color: var(--primary-font-color);
  margin: 15px 0px 5px 0px;
  border-left: 2px solid var(--primary-accent-color);
  padding-left: 5px;
}

p {
  text-align: justify;
  font-weight: 400;
  font-size: 18px;
  color: var(--primary-font-color);
  margin: 10px 0px;
}

.plink {
  color: var(--primary-accent-color);
  font-weight: 500;
}

.plink:hover {
  color: var(--primary-hover-color);
  cursor: pointer;
  text-decoration: underline var(--primary-hover-color);
}

textarea {
  min-width: 400px;
  min-height: 300px;
}

button {
  height: 20px;
  padding: 5px 10px;
  box-sizing: content-box;
  background: var(--primary-accent-color);
  color: var(--primary-font-color);
  border-radius: 50px;
  outline: none;
  border: none;
}

button:disabled {
  background: var(--secondary-accent-color);
}

button:hover {
  cursor: pointer;
}